<template>
  <v-container style="max-width: 950px;">
    <v-row dense>
      <v-col>
        <v-card>
          <v-app-bar flat>
            <v-card-title class="px-0">Nova Notificação</v-card-title>

          </v-app-bar>
          <v-form @submit.prevent="submit">
            <ValidationObserver ref="notification_form">
              <v-card-text>
                <ValidationProvider 
                  name="notification.service_number"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Número do Serviço"
                    v-model="form.service_number"
                    :error-messages="errors"
                  >
                  </v-text-field>
                </ValidationProvider>
                <ValidationProvider 
                  name="notification.lead_type_id"
                  rules="required" 
                  v-slot="{ errors }"
                >
                  <v-select
                    label="Tipo de Lead"
                    v-model="form.lead_type_id"
                    :error-messages="errors"
                    :items="lead_types"
                    item-value="id"
                    item-text="description"
                  />
                </ValidationProvider>
                <ValidationProvider 
                  name="notification.action"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    label="Ação"
                    v-model="form.action"
                    :error-messages="errors"
                    :items="actions"
                    :item-text="itemTextAction"
                    return-object
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="notification.motive"
                  :rules="is_motive_required ? 'required' : ''"
                  v-slot="{ errors }"
                  v-if="is_motive_required"
                >
                  <v-radio-group
                    v-model="form.motive"
                    :label="
                      $capitalize($tc('model.integration_reason_label'))
                    "
                    v-if="form.action"
                    :error-messages="errors"
                    :disabled="submitting"
                  >
                    <v-radio
                      v-for="motive in form.action.motives"
                      :key="motive.external_code"
                      :label="motive.name"
                      :value="motive"
                    ></v-radio>
                  </v-radio-group>
                </ValidationProvider>
                <ValidationProvider
                  v-if="form.motive"
                  name="justification"
                  :rules="is_justification_required ? 'required|': '' + 'max:255'"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    label="Justificativa"
                    v-model="form.justification"
                    outlined
                    :error-messages="errors"
                    :counter="255"
                    name="justification"
                    :disabled="submitting"
                  ></v-textarea>
                </ValidationProvider>
                <ValidationProvider name="notification.inbound" v-slot="{ errors }">
                  <v-switch
                    v-model="form.inbound"
                    label="Inbound"
                    :error-messages="errors"
                  ></v-switch>
                </ValidationProvider>
                <div class="font-weight-medium mt-2">Entidade Principal</div>
                <ValidationProvider 
                  v-if="!form.inbound"
                  name="notification.entity_type"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    label="Tipo"
                    v-model="form.entity_type"
                    :items="entity_types"
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider 
                  name="notification.entity_id"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Identificador"
                    v-model="form.entity_id"
                    :error-messages="errors"
                  >
                  </v-text-field>
                </ValidationProvider>
                <div class="font-weight-medium  mt-2">Entidade Pai</div>
                <ValidationProvider
                  v-if="!form.inbound"
                  name="notification.parent_entity_type"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    label="Tipo"
                    v-model="form.parent_entity_type"
                    :items="entity_types"
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="notification.parent_entity_id"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Identificador"
                    v-model="form.parent_entity_id"
                    :error-messages="errors"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn :loading="submitting" type="submit" color="primary">Criar</v-btn>
              </v-card-actions>
            </ValidationObserver>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createNotification } from "@/services/eps";

export default {
  name: 'eps-new-notification',
  data() {
    return {
      submitting: false,
      form: {
        service_number: '',
        lead_type_id: null,
        action: null,
        motive: null,
        justification: '',
        inbound: true
      },
      entity_types: [
        'Lead',
        'Service',
        'Comment',
        'Attachment'
      ]
    }
  },
  computed: {
    is_justification_required() {
      if (!this.form.motive) return false;

      return this.form.motive.needs_justification;
    },
    is_motive_required() {
      return (this.form?.action?.motives || []).length > 0;
    },
    actions() {
      if (this.form.lead_type_id) {
        return this.$store.getters['eps/getActions']
          .map(action => {
            const external_action = action.external_actions[this.form.lead_type_id];
            
            if (external_action) {
              external_action.canonical = action.canonical;
              external_action.name = action.name;
              return external_action;
            }
          })
          .filter(action => {
            return action;
          });
      }

      return [];
    },
    ...mapState("lead_types", ["lead_types"])
  },
  methods: {
    itemTextAction(item) {
      return item.external_code + ' — ' + item.name 
            + ' Permitido inbound: ' + (item.allow_inbound ? 'Sim' : 'Não')
    },
    resetForm() {
      this.form = {
        service_number: '',
        lead_type_id: null,
        action: null,
        motive: null,
        justification: '',
        inbound: true
      };
      this.$refs.notification_form.reset();
    },
    async submit() {
      const validation = await this.$refs.notification_form.validate();
      if (!validation) return;

      this.submitting = true;
      try {
        const payload = {
          ...this.form,
          action: this.form?.action?.external_code,
          motive: this.form?.motive?.external_code
        };

        await createNotification(payload);
        this.$store.commit('sendMessage', {
          text: 'Notificação criada com sucesso',
          color: 'success'
        });
      } catch (error) {
        if (error?.response?.data?.message) {
          this.$store.commit("sendMessage", {
            text: error?.response?.data?.message,
            color: 'red'
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    ...mapActions("lead_types", ["loadTypes"])
  }, 
  created() {
    this.loadTypes();
  }
}
</script>

<style>

</style>